import React, { useEffect, useState, useMemo, useCallback } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import { jwtService } from "app/auth/jwtService";
import { getUserCarriers } from "app/Api Calls/GetUserCarriers";
import { searchTeams } from "app/Api Calls/SearchTeams";
import { fetchAllTeams } from "app/Api Calls/TeamsRequiredApis";
import { setSelectedTeamIds, setTeamList, setUserCarriers } from "store/ticketFetchSlice";
import settingsConfig from "app/configs/settingConfig";
import { Box } from "@mui/material";

const TeamFilter = () => {
    const dispatch = useDispatch();
    const selectedTeams = useSelector((state) => state.ticketFetchSlice.selectedTeamIds);
    const carriers = useSelector((state) => state.ticketFetchSlice.userCarriers);
    const rawTeams = useSelector((state) => state.ticketFetchSlice.teamList);
    const currentUser = jwtService.getUser();
    const [loading, setLoading] = useState(false);
    const [carriersFetched, setCarriersFetched] = useState(false);
    const [open, setOpen] = useState(false);
    const { assignToMe, assignToTeam } = useSelector((state) => state.ticketFetchSlice.assignment);
    const ticketsLoading = useSelector((state) => state.ticketFetchSlice.ticketsLoading)

    const isTeamLeader = useMemo(() => {
        return settingsConfig.roles.teamLeader.includes(currentUser.roleName);
    }, [currentUser.roleName]);

    const isDisabled = useMemo(() => {
        return (assignToMe || assignToTeam) && isTeamLeader || ticketsLoading;
    }, [assignToMe, assignToTeam, isTeamLeader, ticketsLoading]);

    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        if (currentUser && (!carriers || carriers.length === 0) && !carriersFetched) {
            const fetchUserAndCarriers = async () => {
                const fetchedCarriers = await getUserCarriers(currentUser.id);
                dispatch(setUserCarriers(fetchedCarriers || []));
                setCarriersFetched(true);
            };
            fetchUserAndCarriers();
        }
    }, []);

    useEffect(() => {
        const fetchTeams = async () => {
            const isAdmin = currentUser?.roleName?.includes(settingsConfig.roles.admin);
            setLoading(true);
            try {
                let fetchedTeams = [];
                if (!isAdmin && carriers?.length > 0 && currentUser && currentUser.userGroupId) {
                    const fetchedTeamsResponse = await searchTeams(0, 100, currentUser.userGroupId, carriers[0]?.id, null, null, currentUser?.id);
                    fetchedTeams = fetchedTeamsResponse.elements || [];
                } else if (isAdmin) {
                    fetchedTeams = await fetchAllTeams();
                }

                const teamsWithGroupName = fetchedTeams.map(team => {
                    const GroupRoleId = !isAdmin && team.groupRole ? team.groupRole.id : team.groupRoleId;
                    const groupName = settingsConfig.groupRoles.find(role => role.id === GroupRoleId)?.name.trim() || 'Unknown Group';

                    return {
                        ...team,
                        groupName,
                    };
                });

                dispatch(setTeamList(teamsWithGroupName));
            } catch (error) {
                console.error("Failed to fetch teams:", error);
                dispatch(setTeamList([]));
            } finally {
                setLoading(false);
            }
        };

        if (carriersFetched && carriers) {
            fetchTeams();
        }
    }, [carriers,carriersFetched]);

    const memoizedTeams = useMemo(() => {
        const selectedTeamsCopy = [...selectedTeams];
        const rawTeamsCopy = [...rawTeams];

        const sortedSelectedTeams = selectedTeamsCopy.sort((a, b) => a.level - b.level);
        const sortedRawTeams = rawTeamsCopy.filter(team => !selectedTeamsCopy.some(selected => selected.id === team.id))
            .sort((a, b) => a.name.localeCompare(b.name));

        return {
            selected: sortedSelectedTeams,
            others: sortedRawTeams
        };
    }, [rawTeams, selectedTeams]);

    const sortedOptions = useMemo(() => {
        const groupedOptions = [...memoizedTeams.selected.map(team => ({ ...team, groupName: 'Επιλεγμένες' })), ...memoizedTeams.others];
        return groupedOptions.sort((a, b) => {
            if (a.groupName === 'Επιλεγμένες' && b.groupName !== 'Επιλεγμένες') return -1;
            if (a.groupName !== 'Επιλεγμένες' && b.groupName === 'Επιλεγμένες') return 1;
            if (a.groupName < b.groupName) return -1;
            if (a.groupName > b.groupName) return 1;
            return a.name.localeCompare(b.name);
        });
    }, [memoizedTeams]);


    const handleChange = useCallback((event, newValues) => {
        dispatch(setSelectedTeamIds(newValues));
    }, [dispatch]);

    const handleOpen = useCallback(() => {
        if (!isDisabled) {
            setOpen(true);
        }
    }, [isDisabled]);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const handleClick = () => {
        if (!isDisabled) {
            setOpen(true);
        }
    };

    return (
        <Box>
            <Autocomplete
                multiple
                disablePortal
                id="teams-autocomplete"
                options={sortedOptions}
                groupBy={(option) => option.groupName}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                open={open}
                onOpen={handleOpen}
                readOnly={isDisabled}
                onClose={handleClose}
                ListboxProps={{
                    style: {
                        padding: 0,
                    },
                }}
                sx={{
                    flexGrow: 1,
                    minWidth: "150px",
                    maxWidth: "100%",
                    width: "auto",
                    flexShrink: 1,
                    height: "42px",
                    '.MuiOutlinedInput-root': {
                        height: 42,
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                    },
                    '.MuiAutocomplete-inputRoot': {
                      textOverflow: 'ellipsis'
                    },
                }}
                value={selectedTeams ?? []}
                onChange={handleChange}
                renderTags={(value) => {
                    const displayedTags = value.map(option => option.name).join(', ');
                    return (
                        <div style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '100%',
                            cursor: 'text'
                        }}>
                            {displayedTags}
                        </div>
                    );
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Ομάδα"
                        aria-disabled={isDisabled}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        onClick={handleClick}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
                renderGroup={(params) => (
                    <li key={params.key} style={{ listStyle: 'none', margin: 0, padding: 0 }}>
                        <ul>
                            <li style={{
                                fontWeight: 'bold',
                                backgroundColor: '#f7f7f7',
                                position: 'sticky',
                                top: 0,
                                zIndex: 2,
                                boxShadow: '0 2px 2px -1px rgba(0,0,0,0.2)',
                            }}>{params.group}</li>
                            {params.children}
                        </ul>
                    </li>
                )}
            />
            {isDisabled && !ticketsLoading && isFocused && (
                <div style={{ color: 'red', marginTop: '8px' }}>
                    {assignToMe && 'Πρέπει να απενεργοποιήσετε την επιλογή Ανατεθειμένα σε μένα'}
                    {assignToTeam && 'Πρέπει να απενεργοποιήσετε την επιλογή Ανατεθειμένα στην Ομάδα μου'}
                </div>
            )}
        </Box>
    );
};

export default TeamFilter;
