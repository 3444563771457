
import { axiosApi } from 'app/configs/axiosConfig';
import { useSelector } from 'react-redux';
import performActionOnTicket from './PerformActionOnTicket';

const submitTicket = async (
  selectedResolution,
  activeFlags,
  representativeId,
  erotisi,
  thematicId,
  beneficiaryId,
  activeStatus,
  callerId,
  shouldSkip = false,
  selectedGeographyId = null
) => {
    // const closedTicketStatus = ticketStatuses.find((status) => status.name === 'Κλειστό');

    const userString = localStorage.getItem('user');
    const cleanedString = userString.replace(/\\/g, '').replace(/"{/g, '{').replace(/}"/g, '}');
    const user = JSON.parse(cleanedString);
    const localStorageUserId = user.uuid;

    let ticketFlagCollection = [];

    if (Array.isArray(activeFlags) && activeFlags.length > 0) {
        ticketFlagCollection = activeFlags.map((flag) => ({
            code: flag.code,
            name: flag.name,
        }));
    }


    let flags = {
        ticketFlg: 0,
        priorityFlg: 0,
        representativeFlg: 0,
        followUpFlg: 0,
        applicationFlg: 0,
    };

    if (Array.isArray(activeFlags) && activeFlags.length > 0) {
        flags = {
            ticketFlg: activeFlags.includes('ticketFlg') ? 1 : 0,
            priorityFlg: activeFlags.includes('priorityFlg') ? 1 : 0,
            representativeFlg: activeFlags.includes('representativeFlg') ? 1 : 0,
            followUpFlg: activeFlags.includes('followUpFlg') ? 1 : 0,
            applicationFlg: activeFlags.includes('applicationFlg') ? 1 : 0,
        };
    }


    const payload = {
        title: null,
        shortDescription: null,
        description: erotisi || ' ',
        thematicId: null,
        beneficiaryId: null,
        representativeId: null,
        ...flags,
        ticketFlagCollection,
        ticketStatusId: activeStatus.id,
        geographyId: selectedGeographyId
    };

    if (thematicId) {
        payload.thematicId = thematicId;
    }

    if (beneficiaryId) {
        payload.beneficiaryId = beneficiaryId;
    }

    if (representativeId) {
        payload.representativeId = representativeId;
    }

    if (callerId) {
        payload.callerId = callerId;
    }


    let ticketId;
    let uuid;
    try {
        const response = await axiosApi.post('/ticket', payload);
        ticketId = response.data.id;
        uuid = response.data.uuid;

        if (!shouldSkip && ticketId) {
            const resolutionPayload = {
                actionCode: selectedResolution.id,
                ticketId: ticketId,
            };

            try {
                const resolutionResponse = await performActionOnTicket(resolutionPayload);
                return {resolutionResponse:resolutionResponse ,success: true, ticketId: ticketId, ticket: response.data, uuid: uuid};
            } catch (resolutionError) {
                console.error('An error occurred while posting the resolution for the ticket:', resolutionError);
                throw {ticketId, uuid, error: resolutionError, type: "RESOLUTION_ERROR"};
            }
        }
        return {success: true, ticketId: ticketId, uuid:uuid, ticket: response.data};
      } catch (ticketError) {
        console.error('An error occurred while creating the ticket:', ticketError);
        // Assuming ticketError is an object; if not, adjust as necessary
        ticketError.type = ticketError.type || "TICKET_CREATION_ERROR"; // Ensure existing errors have a type
        throw ticketError;
    }
}

export default submitTicket;