import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getSpecificUser } from "app/Api Calls/GetSpecificUser";
import { setTeam } from "store/editTicketSlice";
import { useSelector } from "react-redux";
import { fetchTeamById } from "app/Api Calls/GetTeamById";
import FlagsTicket from "app/main/Reusable Components/Ticket Reusable/FlagsTicket";
import { changeErotisi, setErotisiEditorState, validateErotisiText } from "store/userInfoSlice";
import TicketStatus from "app/main/Reusable Components/Ticket Reusable/TicketStatus";
import CommentsEditAccordion from "./CommentsEditAccordion";

function TicketEditAccordion({
  id,
  ticket,
  expandedPanels,
  onPanelToggle,
  panelRefs,
  callerId,
}) {
  const dispatch = useDispatch();
  const team = useSelector((state) => state.editTicketSlice.team);
  const [userDetails, setUserDetails] = useState(null);
  useEffect(() => {
    if (ticket && ticket.description) {
      dispatch(setErotisiEditorState(ticket.description));
      dispatch(validateErotisiText(ticket.description));
    }
    if (ticket && ticket.assigneeUserId) {
      async function fetchUserDetails() {
        try {
          const fetchedUserDetails = await getSpecificUser(ticket.assigneeUserId);
          setUserDetails(fetchedUserDetails);
        } catch (error) {
          console.error('Error fetching user details:', error);
        }
      }
      fetchUserDetails();
    }
    if (ticket && ticket.teamId) {
      async function fetchTeamDetails() {
        try {
          const fetchedTeam = await fetchTeamById(ticket.teamId);
          dispatch(setTeam(fetchedTeam));
        } catch (error) {
          console.error('Error fetching team details:', error);
        }
      }
      fetchTeamDetails();
    }
  }, [dispatch, id, ticket]);



  return (
    <Accordion
      className="bg-gray-200"
      expanded={expandedPanels.includes("Τicket")}
      onChange={() => onPanelToggle("Τicket")}
      ref={panelRefs["Τicket"]}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <h3>Ticket</h3>
      </AccordionSummary>
      <AccordionDetails>
        <div className="w-full grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-3">
          {/* Ticket Number and Assigned User */}
          <div className="col-span-1">
            <strong style={{ fontSize: '14px' }}>
              Αριθμός Ticket : {ticket && ticket.uuid}
            </strong>
            {userDetails && (
              <div style={{ fontSize: '14px' }}>
                <strong>Ανατεθειμένο σε: </strong>
                {userDetails.firstName} {userDetails.lastName} (
                {userDetails.roleCollection[0]?.roleName?.replace('ROLE_', '').replace(/_/g, ' ')})
                {team && <span> - <strong>Ομάδα:</strong> {team.name}</span>}
              </div>
            )}
          </div>

          {/* Caller ID */}
          <div className="col-span-1">
            <div style={{ fontSize: '14px' }}>
              <strong>Εισερχόμενο Τηλέφωνο : </strong>
              {callerId === -1 ? "Απόκρυψη Αριθμού" : callerId}
            </div>
          </div>

          {/* Ticket Status and Date */}
          <div className="col-span-1">
            <TicketStatus
              isEdit
              ticketStatusId={ticket ? ticket.ticketStatusId : null}
              ticketId={ticket ? ticket.id : null}
            />
            <div style={{ fontSize: '14px' }}>
              <strong> Ημερομηνία Καταχώρησης : </strong>
              {ticket && new Date(ticket.createdAt).toLocaleDateString("el-GR")}
            </div>
          </div>

          {/* FlagsTicket Component */}
          <div className="col-span-3 mt-4">
            <div style={{ maxWidth: '500px' }}>
              <FlagsTicket />
            </div>
          </div>

          {/* Ticket Question */}
          <div className="col-span-3">
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-xl font-bold">Ερώτηση Ticket</h2>
            </div>
            <div
              className="description-container overflow-auto mb-4"
              style={{
                maxHeight: "200px",
                minHeight: "200px",
                backgroundColor: "white",
                padding: "16px"
              }}
              dangerouslySetInnerHTML={{
                __html:
                  ticket &&
                    ticket.description !== null &&
                    ticket.description.trim() !== ""
                    ? ticket.description
                    : "<p></p>",
              }}
            />
            <CommentsEditAccordion id={id} sourceApplication={ticket?.sourceApplication?.id} status={ticket?.status} />
          </div>
        </div>
      </AccordionDetails>



    </Accordion>
  );
}

export default TicketEditAccordion;
