import { createSlice } from "@reduxjs/toolkit";

const historyTicketSlice = createSlice({
  name: "tickets",
  initialState: {
    ticketsData: {},
    historyTickets: [],
    thematics: {},
    users: {},
    actionCodes: {},
    currentPage: 1,  
    totalPages: 0,
    historyDataFetched: {},
    totalNumberOfTickets: 0,
    selectedCarrier: null
  },
  reducers: {
    setTicketsData(state, action) {
      const { page, data, isFiltered, selectedCarrier } = action.payload;
      state.ticketsData[page] = {
        data,
        isFiltered,
        selectedCarrier
      };
    },
    
    
    setHistoryTickets(state, action) {
      state.historyTickets = action.payload;
    },
    setSelectedCarrier(state, action) {
      state.selectedCarrier = action.payload;
    },
    setThematics(state, action) {
      state.thematics = { ...state.thematics, ...action.payload };
    },
    setUsers(state, action) {
      state.users = action.payload;
    },
    setActionCodes(state, action) {
      state.actionCodes = action.payload;
    },
    clearTicketsData(state) {
      state.ticketsData = {};
      state.historyTickets = [];
      state.thematics = {};
      state.users = {};
      state.actionCodes = {};
      state.historyDataFetched = {};
      state.currentPage = 1;  
      state.totalPages = 0;
      state.totalNumberOfTickets = 0;
      state.selectedCarrier = null;
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    setTotalPages(state, action) {
      state.totalPages = action.payload;
    },
    setTotalNumberOfTickets(state, action) {
      state.totalNumberOfTickets = action.payload;
    },
    setHistoryDataFetched(state, action) {
     
      if (!state.historyDataFetched.hasOwnProperty(action.payload.ticketId)) {
        state.historyDataFetched[action.payload.ticketId] = true;
      }
     
    },
    
    
  },
});

export const {
  setTicketsData,
  setHistoryTickets,
  setThematics,
  setUsers,
  setActionCodes,
  clearTicketsData,
  setHistoryDataFetched,
  setCurrentPage,
  setTotalPages,
  setTotalNumberOfTickets,
  setSelectedCarrier
} = historyTicketSlice.actions;

export default historyTicketSlice.reducer;
