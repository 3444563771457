import { axiosApi } from "app/configs/axiosConfig";

export const getBeneficiaryByAfm = async (afm, page, size) => {
  try {
    const response = await axiosApi.post(`/beneficiary/search/${page}/${size}`, {
      afm: afm
    });
    return response.data.elements[0];
  } catch (error) {
    console.error(error);
  }
};

// Get Beneficiary By Amka
export const getBeneficiaryByAmka = async (amka, page, size) => {
  try {
    const response = await axiosApi.post(`/beneficiary/search/${page}/${size}`, {
      amka: amka
    });
    return response.data.elements[0];
  } catch (error) {
    console.error(error);
  }
};

export const getBeneficiaryBySurname = async (surname, page, size) => {
  try {
    const response = await axiosApi.post(`/beneficiary/search/${page}/${size}`, {
      lastName: surname
    });
    // Return all matching beneficiaries instead of just the first one
    return response.data;
  } catch (error) {
    console.error(error);
  }
};


// Get Beneficiary By Mobile
export const getBeneficiaryByPhone = async (phone, page, size) => {
  try {
    const response = await axiosApi.post(`/beneficiary/search/${page}/${size}`, {
      phone: phone
    });
    return response.data.elements;
  } catch (error) {
    console.error(error);
  }
};

export const getBeneficiaryByMobile = async (mobile, page, size) => {
  try {
    const response = await axiosApi.post(`/beneficiary/search/${page}/${size}`, {
      mobile: mobile
    });
    return response.data.elements;
  } catch (error) {
    console.error(error);
  }
};



  export const searchUsers = async ({ searchPayload }, page, size) => {
    try {
      const response = await axiosApi.post(`/admin/user/search/${page}/${size}`, searchPayload);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };
  
  
